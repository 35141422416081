<template>
  <div>
    <b-modal id="filter" ok-only centered size="md" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Filter</div>
      </template>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Jenis Badan Hukum</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="1">Perkumpulan</b-form-select-option>
                  <b-form-select-option :value="2">Yayasan</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center" v-if="typeUser === 'admin'">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Provinsi</p>
              </b-col>:<b-col sm="6">
                <b-form-select v-model="selectedProvinsi">
                  <b-form-select-option :value="item.kode" v-for="(item,index) in listProvinsi" :key="index">
                    {{item.nama}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center" v-if="typeUser === 'prov'">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filter.kabupaten_kota" placeholder="Silahkan Pilih Kabupaten/Kota">
                  <b-form-select-option :value="item.kode" v-for="(item,index) in listKota" :key="index">{{item.nama}}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tanggal Permohonan</p>
              </b-col>:<b-col sm="6">
                <b-form-input type="date" v-model="filter.kabupaten_kota" placeholder="Silahkan Pilih Kabupaten/Kota">
                </b-form-input>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('filter')">
            Batal
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="submitFilterBadanHukum()">
            Terapkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-detail-permohonan" ok-only centered size="xl" content-class="shadow" hide-header hide-footer style="background-color:#FAFAFA;">
      <div style="background-color:#FAFAFA;" v-if="det && ormasIdentify">
        <b-container>
          <b-row class="pb-5 mb-2">
            <b-col cols="12">
              <b-row class="pt-5 mt-2">
                <b-col class="d-flex justify-content-between">
                  <b style="font-size:20px;">
                    Detail Perubahan Ormas
                  </b>
                  <div>
                    <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" @click="$bvModal.hide('modal-detail-permohonan')">
                      <v-icon class="pr-3">mdi-close</v-icon>
                      Tutup
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2" v-if="ormasIdentify">
            <b-col cols="2" style="font-weight:600;">
              Nama Ormas
            </b-col>
            <b-col >
              : {{ ormasIdentify.namaOrmas }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Ciri Khusus
            </b-col>
            <b-col >
              : {{ ormasIdentify.namaCiriKhusus }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Jenis Perubahan
            </b-col>
            <b-col class="d-flex">
              : &nbsp;<span v-for="(item,index) in det.jenisPerubahan.mJenisPerubahanIds" :key="index">
                    {{ item == 1 ? 'Profil, ' : '' }}
                    {{ item == 2 ? 'Struktur, ' : '' }}
                    {{ item == 3 ? 'Rekening, ' : '' }}
                    {{ item == 4 ? 'Cabang' : '' }}
                    </span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Tanggal Permohonan
            </b-col>
            <b-col >
              : {{ det.jenisPerubahan.tanggalPerubahan | moment('DD MMMM YYYY')}}
            </b-col>
          </b-row>
          <b-row class="mb-2 mt-5" v-if="statusPerubahan != 'Ditolak' && statusPerubahan != 'Diterima'">
              <b-col cols="2" class="d-flex align-items-center" style="font-weight:600;margin:0px;">
                Aksi Permohonan
              </b-col>
              <b-col >
                : <b-button :disabled="loadVerif" variant="danger" size="sm" class="mr-3" @click="verifPerubahan(2)">
                  <b-spinner medium v-if="loadVerif" ></b-spinner>
                  <span v-else><v-icon>
                    mdi-close-circle
                  </v-icon>
                  Tolak</span>
                  </b-button>
                  <b-button variant="success" :disabled="loadVerif" size="sm" @click="verifPerubahan(1)">
                    <b-spinner medium v-if="loadVerif" ></b-spinner>
                  <span v-else><v-icon>
                    mdi-check-circle
                  </v-icon>
                  Terima </span></b-button>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="statusPerubahan == 'Ditolak' || statusPerubahan == 'Diterima'">
              <b-col cols="2" class="d-flex align-items-center" style="font-weight:600;margin:0px;">
                Tanggal Persetujuan
              </b-col>
              <b-col >
                : {{tanggalPersetujuanV | moment('DD-MM-YYYY')}}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="statusPerubahan == 'Ditolak' || statusPerubahan == 'Diterima'">
              <b-col cols="2" class="d-flex align-items-center" style="font-weight:600;margin:0px;">
                Status Permohonan
              </b-col>
              <b-col >
                : {{ statusPerubahan}}
              </b-col>
            </b-row>

          <b-tabs pills card justified v-model="tabDetailRiwayat" class="pt-5" style="border-radius:10px;">
          
          <b-tab title="Data Saat Ini" style="background-color:white;margin-top:0px;">
              <b-row class="mb-3">
                <b-col>
                  <b style="font-size:16px;">
                    Profil
                  </b>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Telepon
                    </b-col>
                    <b-col >
                      : {{ currentData.profil.no_telp}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Fax
                    </b-col>
                    <b-col >
                      : {{ currentData.profil.no_fax}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Ciri Khusus
                    </b-col>
                    <b-col >
                      : {{ currentData.profil.masterCiriKhusus.nama_ciri_khusus}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Bidang Kegiatan
                    </b-col>
                    <b-col class="d-flex">
                      : &nbsp;<ul class="ml-5">
                          <li v-for="(item,index) in currentData.profil.bidangKegiatans" :key="index">{{ item.masterJenisOrmas.nama_jenis_ormas }}</li>
                        </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Wadah Berhimpun
                    </b-col>
                    <b-col v-if="currentData.berhimpunOtonom.wadahBerhimpun">
                      : {{ currentData.berhimpunOtonom.wadahBerhimpun.afiliasiOrmas.nama_ormas}}
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 mt-5">
                    <b-col style="font-weight:600;">
                      Badan Otonom / Sayap Organisasi
                    </b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col>
                        <div class="table-responsive">
                              <table
                                class="
                                  table
                                  table-head-custom
                                  table-vertical-center
                                  table-head-bg
                                  table-bordered
                                "
                              >
                                <thead>
                                  <tr class="text-left">
                                    <th style="width: 20px"  class="text-capitalize">No.</th>
                                    <th style="min-width: 190px"  class="text-capitalize">Nama Organisasi</th>
                                    <th style="min-width: 100px"  class="text-capitalize">Nomor Registrasi</th>
                                  </tr>
                                </thead>
                                <tbody style="background:#FAFAFA;" v-if="currentData.berhimpunOtonom.badanOtonom.length > 0">
                                  <template v-for="(item, i) in currentData.berhimpunOtonom.badanOtonom">
                                    <tr v-bind:key="i" >
                                      <td>
                                        <span
                                          style="width: 20px"
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  "
                                          >{{ i + 1 }}</span
                                        >
                                      </td>
                                      <td>
                                        <span v-if="item.afiliasiOrmas"
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  "
                                          >{{ item.afiliasiOrmas.nama_ormas }}</span
                                        >
                                      </td>
                                      <td>
                                        <span v-if="item.afiliasiOrmas"
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  "
                                          >{{ item.afiliasiOrmas.kode_transaksi }}</span
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="d-flex justify-content-center">
                    <b-row class="text-right">
                        <img v-if="currentData.profil.file_logo !== null && currentData.profil.file_logo !== '' " :src="fileURL + '/' + currentData.profil.file_logo"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          @error="replaceByDefault"
                          />
                        <img
                          v-if="currentData.profil.file_logo == null || currentData.profil.file_logo == ''"
                          src="/assets/images/default-thumbnail.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />

                        <img v-if="currentData.profil.file_bendera !== null && currentData.profil.file_bendera !== ''" :src="fileURL + '/' + currentData.profil.file_bendera"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          @error="replaceByDefault"
                        />
                        <img
                        v-if="currentData.profil.file_bendera == null || currentData.profil.file_bendera == ''"
                          src="/assets/images/default-thumbnail.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                      </b-row>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <b style="font-size:16px;">
                    Struktur Organisasi
                  </b>
                </b-col>
              </b-row>
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                  <div class="tab-content">
                    <!--begin::Table-->
                    <div class="table-responsive">
                      <table
                        class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                "
                      >
                        <thead>
                          <tr class="text-left">
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                            <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                            <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, i) in pengurus">
                            <tr v-bind:key="i">
                              <td>
                                <img v-if="item.foto != null && item.foto !== ''" :src="fileURL + '/' + item.foto" @error="replaceByDefault" style="width:64px;height:64px;" /> 
                                <img
                                v-if="item.foto == null || item.foto == ''"
                                  src="/assets/images/default-thumbnail.jpg"
                                  style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                                  alt="abstract"
                                /> 
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.nama_pengurus }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{  item.periode_selesai | moment("DD/MM/YYYY") }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.masterJabatan.nama_jabatan }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.identitas }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          "
                                  >{{ item.no_hp }}</span
                                >
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                      
                    <!--end::Table-->
                    <div class="mt-5" v-if="currentData.listPengurus.length > 0">
                      <b-row class="d-flex align-items-center mb-5">
                    <b-col sm="4"
                      ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p></b-col
                        >:<b-col sm="6">
                          <b-button v-if="currentData.profil && currentData.profil.file_sk_pengurus != null && currentData.profil.file_sk_pengurus != '' " 
                          @click="newTab(fileURL + '/' + currentData.profil.file_sk_pengurus)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan</b-button></b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                    </div>
                  </div>
            </div>

            <b-row class="mb-3 mt-3">
              <b-col>
                <b style="font-size:16px">
                  Rekening
                </b>
              </b-col>
            </b-row>
            <div class="table-responsive">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                      <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in currentData.rekening">
                      <tr v-bind:key="i">
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ i + 1 }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.no_rekening}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.nama_bank}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{item.nama_pemilik_rekening}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{item.id_jenis_rekening}}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <b-row class="mb-3 mt-8">
              <b-col>
                <b style="font-size:16px">
                  Cabang Ormas
                </b>
              </b-col>
            </b-row>
            <div class="table-responsive">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                      <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                      <th style="min-width: 50px" class="text-capitalize">Kabupaten / Kota</th>
                      <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in currentData.cabangOrmas">
                      <tr v-bind:key="i">
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ i + 1 }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.masterLokasiSekretariat.nama_lokasi}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.alamat_sekretariat}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.wilayahKabupaten.nama}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.wilayahProvinsi.nama}}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
          </b-tab>

          <b-tab title="Data Yang Diubah" style="background-color:white;margin-top:0px;">
              <b-row class="mb-3">
                <b-col>
                  <b style="font-size:16px;">
                    Profil
                  </b>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <b-row class="mb-2" v-if="changedData.profile">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Telepon
                    </b-col>
                    <b-col >
                      : {{ changedData.profile.noTelp}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.profile">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Fax
                    </b-col>
                    <b-col v-if="changedData.profile">
                      : {{ changedData.profile.noFax}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.profile">
                    <b-col cols="4" style="font-weight:600;">
                      Ciri Khusus
                    </b-col>
                    <b-col v-if="changedData.profile">
                      : {{ changedData.profile.masterCiriKhusus.nama_ciri_khusus}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.profile && changedData.profile.bidangKegiatans">
                    <b-col cols="4" style="font-weight:600;">
                      Bidang Kegiatan
                    </b-col>
                    <b-col class="d-flex">
                      : &nbsp;<ul class="ml-5">
                          <li v-for="(item,index) in changedData.profile.bidangKegiatans" :key="index">{{ item.masterJenisOrmas.nama_jenis_ormas }}</li>
                        </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.berhimpunOtonom">
                    <b-col cols="4" style="font-weight:600;">
                      Wadah Berhimpun
                    </b-col>
                    <b-col >
                      : {{ changedData.berhimpunOtonom.wadahBerhimpun.dataOrmas.nama_ormas}}
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 mt-5" v-if="changedData.berhimpunOtonom">
                    <b-col style="font-weight:600;">
                      Badan Otonom / Sayap Organisasi
                    </b-col>
                  </b-row>

                  <b-row class="mb-2" v-if="changedData.berhimpunOtonom">
                    <b-col>
                        <div class="table-responsive">
                              <table
                                class="
                                  table
                                  table-head-custom
                                  table-vertical-center
                                  table-head-bg
                                  table-bordered
                                "
                              >
                                <thead>
                                  <tr class="text-left">
                                    <th style="width: 20px"  class="text-capitalize">No.</th>
                                    <th style="min-width: 190px"  class="text-capitalize">Nama Organisasi</th>
                                    <th style="min-width: 100px"  class="text-capitalize">Nomor Registrasi</th>
                                  </tr>
                                </thead>
                                <tbody style="background:#FAFAFA;" v-if="changedData.berhimpunOtonom.badanOtonom.length > 0">
                                  <template v-for="(item, i) in changedData.berhimpunOtonom.badanOtonom">
                                    <tr v-bind:key="i" >
                                      <td>
                                        <span
                                          style="width: 20px"
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  "
                                          >{{ i + 1 }}</span
                                        >
                                      </td>
                                      <td>
                                        <span
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  "
                                          >{{ item.dataOrmas.nama_ormas }}</span
                                        >
                                      </td>
                                      <td>
                                        <span
                                          class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  "
                                          >{{ ['tidak tersedia'] }}</span
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="d-flex justify-content-center" v-if="changedData.profile">
                    <img v-if="changedData.profile.file_logo !== null && changedData.profile.file_logo !== '' " :src="fileURL + '/' + changedData.profile.file_logo"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          @error="replaceByDefault"
                          />
                        <img
                          v-if="changedData.profile.file_logo == null || changedData.profile.file_logo == ''"
                          src="/assets/images/default-thumbnail.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />

                        <img v-if="changedData.profile.file_bendera !== null && changedData.profile.file_bendera !== ''" :src="fileURL + '/' + changedData.profile.file_bendera"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          @error="replaceByDefault"
                        />
                        <img
                        v-if="changedData.profile.file_bendera == null || changedData.profile.file_bendera == ''"
                          src="/assets/images/default-thumbnail.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-3" v-if="changedData.listPengurus">
                <b-col>
                  <b style="font-size:16px;">
                    Struktur Organisasi
                  </b>
                </b-col>
              </b-row>
              <div class="card-body pt-0 pb-3 pl-0 pr-0" v-if="changedData.listPengurus">
                  <div class="tab-content">
                    <!--begin::Table-->
                    <div class="table-responsive">
                      <table
                        class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                "
                      >
                        <thead>
                          <tr class="text-left">
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                            <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                            <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, i) in pengurus">
                            <tr v-bind:key="i">
                              <td>
                                <img v-if="item.foto !== null" :src="fileURL + '/' + item.foto"
                                style="width:64px;height:64px;"
                                alt="abstract"
                                @error="replaceByDefault"
                                />
                              <img
                                v-if="item.foto == null"
                                src="https://alppetro.co.id/dist/assets/images/default.jpg"
                                style="width:64px;height:64px;"
                                @error="replaceByDefault"
                                alt="abstract"
                              />
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.nama_pengurus }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{  item.periode_selesai | moment("DD/MM/YYYY") }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.masterJabatan.nama_jabatan }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.identitas }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          "
                                  >{{ item.no_hp }}</span
                                >
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                      
                    <!--end::Table-->
                    <div class="mt-5" v-if="changedData.listPengurus && changedData.listPengurus.length > 0">
                      <b-row class="d-flex align-items-center mb-5">
                    <b-col sm="4"
                      ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p></b-col
                        >:<b-col sm="6">
                          <b-button v-if="currentData.profil && currentData.profil.file_sk_pengurus != null && currentData.profil.file_sk_pengurus != '' " 
                          @click="newTab(fileURL + '/' + currentData.profil.file_sk_pengurus)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan</b-button></b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.namaPengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.namaPengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.namaPengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.namaPengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                    </div>
                  </div>
            </div>

            <b-row class="mb-3 mt-3" v-if="changedData.rekening">
              <b-col>
                <b style="font-size:16px">
                  Rekening
                </b>
              </b-col>
            </b-row>
            <div class="table-responsive" v-if="changedData.rekening">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                      <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in changedData.rekening">
                      <tr v-bind:key="i">
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ i + 1 }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.noRekening}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.namaBank}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{item.namaPemilikRekening}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{item.id_jenis_rekening}}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <b-row class="mb-3 mt-8" v-if="changedData.cabangOrmas">
              <b-col>
                <b style="font-size:16px">
                  Cabang Ormas
                </b>
              </b-col>
            </b-row>
            <div class="table-responsive" v-if="changedData.cabangOrmas">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                      <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                      <th style="min-width: 50px" class="text-capitalize">Kabupaten / Kota</th>
                      <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in changedData.cabangOrmas">
                      <tr v-bind:key="i">
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ i + 1 }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.masterLokasiSekretariat.nama_lokasi}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.alamatSekretariat}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    "
                            >{{ item.wilayahKabupatenKota.nama}}</span
                          >
                        </td>
                        <td>
                          <span
                            class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    "
                            >{{ item.wilayahProvinsi.nama}}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
          </b-tab>

          </b-tabs>

        </b-container>
      </div>
    </b-modal>

    <b-modal id="modal-proses-permit-detai-badan-hukum" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center" style="display: flex;justify-content: center;">
              <b-col sm="5" style="display: flex;justify-content: center; flex-direction: column; align-items: center; padding-bottom: 2vw;">
                <p style="font-weight: 800; margin:0px;">
                  Permohonan Detail Informasi Ormas
                </p>
                <p style="font-weight: 800; margin:0px;">
                  {{ detailaksesobh.nama_ormas }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nama Pengguna</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_user }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">NIP Pengguna</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nip_user }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nama Instansi</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_instansi }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tanggal Permohonan</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.tanggal_permintaan | moment('DD - MM - YYYY')  }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Ormas Badan Hukum Yang Dimohonkan</p>
              </b-col>:<b-col sm="6">{{ detailaksesobh.nama_ormas }} 
                <b-button @click="checkobh(detailaksesobh.nama_ormas, detailaksesobh.nomor_sk )" size="sm" variant="success">
                  <v-icon style="color:white;" small>mdi-eye</v-icon>
                  Lihat Data Ormas
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nomor SK Ormas Badan Hukum</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nomor_sk }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Jenis Ormas Badan Hukum </p>
              </b-col>:<b-col sm="6">
                <div v-if="detailaksesobh.jenis_ormas == 1">{{ "Perkumpulan" }}</div>
                <div v-if="detailaksesobh.jenis_ormas == 2">{{ "Yayasan" }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Provinsi</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_prov }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_kabkot }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tujuan Permohonan</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.alasan }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Dokumen Pendukung</p>
              </b-col>:<b-col sm="6">
                <a v-if="detailaksesobh.path_file && detailaksesobh.path_file != ''" :href="fileURL + '/' +detailaksesobh.path_file" target="_blank" type="button">
                  <b-button variant="success" size="sm">
                    <v-icon style="color:white;" small>mdi-eye</v-icon>
                    Lihat File
                  </b-button>
                </a>
                <div v-if="!detailaksesobh.path_file && detailaksesobh.path_file === ''" :href="fileURL + '/' +detailaksesobh.path_file" target="_blank" type="button">
                  {{ "tidak ada dukumen pendukung" }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('modal-proses-permit-detai-badan-hukum')">
            Kembali
          </b-button>
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" style="background-color: firebrick; color: #FAFAFA;" @click="submitTolakMintaAkses(detailaksesobh.id)">
            Tolak
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="submitIzinkanMintaAkses(detailaksesobh.id)">
            Izinkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-kasih-permit-detai-badan-hukum" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center" style="display: flex;justify-content: center;">
              <b-col sm="5" style="display: flex;justify-content: center; flex-direction: column; align-items: center; padding-bottom: 2vw;">
                <p style="font-weight: 800; margin:0px;">
                  Permohonan Detail Informasi Ormas
                </p>
                <p style="font-weight: 800; margin:0px;">
                  {{ detailaksesobh.nama_ormas }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Keterangan Penolakan</p>
              </b-col>:<b-col sm="6">
                <b-form-input v-model="alasan_tolak" placeholder="berapa hari durasi yang diberikan">
                  </b-form-input>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('modal-kasih-permit-detai-badan-hukum'), $bvModal.show('modal-proses-permit-detai-badan-hukum')">
            Kembali
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="GakKasihAkses()">
            Proses
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-detail-permit-detai-badan-hukum" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center" style="display: flex;justify-content: center;">
              <b-col sm="5" style="display: flex;justify-content: center; flex-direction: column; align-items: center; padding-bottom: 2vw;">
                <p style="font-weight: 800; margin:0px;">
                  Permohonan Detail Informasi Ormas
                </p>
                <p style="font-weight: 800; margin:0px;">
                  {{ detailaksesobh.nama_ormas }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nama Pengguna</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_user }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">NIP Pengguna</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nip_user }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nama Instansi</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_instansi }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tanggal Permohonan</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.tanggal_permintaan | moment('DD - MM - YYYY')  }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Ormas Badan Hukum Yang Dimohonkan</p>
              </b-col>:<b-col sm="6">{{ detailaksesobh.nama_ormas }} 
                <b-button @click="checkobh(detailaksesobh.nama_ormas, detailaksesobh.nomor_sk )" size="sm" variant="success">
                  <v-icon style="color:white;" small>mdi-eye</v-icon>
                  Lihat Data Ormas
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Nomor SK Ormas Badan Hukum</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nomor_sk }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Jenis Ormas Badan Hukum </p>
              </b-col>:<b-col sm="6">
                <div v-if="detailaksesobh.jenis_ormas == 1">{{ "Perkumpulan" }}</div>
                <div v-if="detailaksesobh.jenis_ormas == 2">{{ "Yayasan" }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Provinsi</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_prov }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.nama_kabkot }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tujuan Permohonan</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.alasan }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Dokumen Pendukung</p>
              </b-col>:<b-col sm="6">
                <a v-if="detailaksesobh.path_file && detailaksesobh.path_file != ''" :href="fileURL + '/' +detailaksesobh.path_file" target="_blank" type="button">
                  <b-button variant="success" size="sm">
                    <v-icon style="color:white;" small>mdi-eye</v-icon>
                    Lihat File
                  </b-button>
                </a>
                <div v-if="!detailaksesobh.path_file && detailaksesobh.path_file === ''" :href="fileURL + '/' +detailaksesobh.path_file" target="_blank" type="button">
                  {{ "tidak ada dukumen pendukung" }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p v-if="detailaksesobh.status === '2'" style="font-weight: 600; margin:0px;">Tanggal Pemberian Akses</p>
                <p v-if="detailaksesobh.status === '3'" style="font-weight: 600; margin:0px;">Tanggal Penolakan Akses</p>
              </b-col>:<b-col sm="6">
                <div>{{ detailaksesobh.tanggal_akses | moment('DD - MM - YYYY')  }}</div>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p v-if="detailaksesobh.status === '2'" style="font-weight: 600; margin:0px;">Status Akses Detail</p>
                <p v-if="detailaksesobh.status === '3'" style="font-weight: 600; margin:0px;">Keterangan Penolakan</p>
              </b-col>:<b-col sm="6">
                <div v-if="detailaksesobh.status === '2' && new Date(detailaksesobh.tanggal_berakhir_akses) < Date.now()">Berakhir sejak {{ detailaksesobh.tanggal_berakhir_akses | moment('DD - MM - YYYY')  }}</div>
                <div v-if="detailaksesobh.status === '2' && new Date(detailaksesobh.tanggal_berakhir_akses) > Date.now()">Akan berakhir pada {{ detailaksesobh.tanggal_berakhir_akses | moment('DD - MM - YYYY')  }}</div>
                <div v-if="detailaksesobh.status === '3'">{{ detailaksesobh.keterangan }}</div>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('modal-detail-permit-detai-badan-hukum')">
            Kembali
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-profil-badan-hukum" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid>
          <b-row class="pt-5 mt-2 pr-0 pl-0">
            <b-col class="d-flex justify-content-between pr-0 pl-0">
              <b style="font-size:20px;">
                Profil Ormas Badan Hukum
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="$bvModal.hide('modal-profil-badan-hukum')">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-tabs pills card id="detail-tab" justified v-model="tabDetail" style="border-radius:10px;width:100%;">

              <b-tab title="Profil" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                      </b-col>:<b-col sm="6" style="white-space: pre-wrap">{{ nama }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Jenis Badan Hukum" }}</p>
                      </b-col>:<b-col sm="6" v-if="jenis_ormas_badan_hukum === 1">{{ 'Perkumpulan'}}</b-col>
                      <b-col sm="6" v-if="jenis_ormas_badan_hukum === 2">{{ 'Perkumpulan'}}</b-col>
                      <b-col sm="6" v-if="jenis_ormas_badan_hukum === 0">{{ '-'}}</b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Nomor SK Badan Hukum" }}</p>
                      </b-col>:<b-col sm="6">{{ nomor_sk }}
                      </b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Tanggal Terbit SK Badan Hukum" }}</p>
                      </b-col>:
                      <b-col sm="6" style="white-space: pre-wrap">
                        {{ tanggal_sk | moment('DD - MM - YYYY')}}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Alamat sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.alamat }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "RT" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.rt }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "RW" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.rw }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Kelurahan" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.kode_kelurahan }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Kecamatan" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.kode_kecamatan }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                      </b-col>:<b-col sm="6">{{ nama_kedudukan_kabupaten }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Provinsi" }}</p>
                      </b-col>:<b-col sm="6">{{ nama_kedudukan_provinsi }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Sosial" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_sosial === '{}' || ket_sosial === null || ket_sosial === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_sosial }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Sosial Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_sosial_lainnya === '{}' || ket_sosial_lainnya === null || ket_sosial_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_sosial_lainnya }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Kemanusiaan" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_kemanusiaan === '{}' || ket_kemanusiaan === null || ket_kemanusiaan === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_kemanusiaan }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Kemanusiaan Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_kemanusiaan_lainnya === '{}' || ket_kemanusiaan_lainnya === null || ket_kemanusiaan_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_kemanusiaan_lainnya }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Keagamaan" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_keagamaan === '{}' || ket_keagamaan === null || ket_keagamaan === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_keagamaan }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Keagamaan Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_keagamaan_lainnya === '{}' || ket_keagamaan_lainnya === null || ket_keagamaan_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_keagamaan_lainnya }}
                        </ul>
                      </b-col>
                      <b-row>
                        <b-col sm="3">
                          <p style="font-weight: 600">
                            {{ "Maksud Asas Tujuan" }}
                          </p>
                        </b-col>:<b-col sm="6"><div v-html="maksud.asas_tujuan"></div></b-col>
                      </b-row>
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Notaris</p>
                      </b-col>:<b-col sm="6">{{ nama_notaris }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Kedudukan Notaris</p>
                      </b-col>:<b-col sm="6">{{ kedudukan_notaris }} </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Notaris Pengganti</p>
                      </b-col>:<b-col sm="6">{{ notaris_pengganti }}
                      </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ nomor_akta_terakhir }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ tanggal_akta_terakhir | moment("DD - MM - YYYY") }} </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Struktur Ormas" style="background-color:white;margin-top:0px;">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. HP</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in pengurus">
                          <tr v-bind:key="i">
                            <td>
                              <span class=" text-dark-75 font-weight-bolder d-block text-left font-size-lg
                        ">{{ item.data.nama_pengurus }}</span>
                            </td>
                            
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.data.jabatan_pengurus ? item.data.jabatan_pengurus : '-' }}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-left
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.data.no_ktp_passport_pengurus }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{item.data.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{ item.data.notelp_pengurus }}</span>
                            </td>
                            <td>
                              <b-button @click="newTab(item.data.no_ktp_passport_pengurus)"
                                size="sm" variant="success">
                                <v-icon style="color:white;" small>
                                  mdi-eye
                                </v-icon>
                                Detail {{ item.data.jabatan_pengurus }}
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  
                </div>
              </b-tab>
            </b-tabs>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <div v-if="pemerintaDearah">
      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Permohonan Perubahan</span>
        </b-col>
      </b-row>
      <div class="table-responsive mt-4" style="border-radius:15px;">
        <table class="table table-head-custom table-vertical-center table-head-bg table-bordered" style="font-size:14px !important;">
          <thead>
            <tr class="text-left">
              <!-- id, id_user, tanggal_permintaan, tanggal_akses, tanggal_berakhir_akses, alasan, path_file, nama_kabkot, nama_prov, status, nama_ormas, nomor_sk, nama_user, nip_user, keterangan, nama_instansi, jenis_ormas -->
              <th style="width: 20px" class="text-capitalize">No.</th>
              <th style="min-width: 50px" class="text-capitalize">Nama Pengguna</th>
              <th style="min-width: 50px" class="text-capitalize">Instansi Pengguna</th>
              <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
              <th style="min-width: 50px" class="text-capitalize">Jenis Ormas</th>
              <th style="min-width: 50px" class="text-capitalize">Aksi</th>
            </tr>
          </thead>
          <tbody v-if="paginateData.length == 0">
            <template>
              <tr>
                <td colspan="6"> -- Tidak ada permohonan -- </td>
              </tr>
            </template>
          </tbody>
          <tbody style="background: #FFFFFF;">
            <template v-for="(item, i) in paginateData">
              <tr v-bind:key="i">
                <td >
                  <span class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ i + 1}}</span>
                </td>
                <td class="text-left">
                  <span  class="
                  text-dark-75
                  font-weight-bolder
                  text-left
                  font-size-lg
                "> {{ item.dataOrmas.nama_ormas}}
                  </span>
                </td>
                <td class="text-left">
                  <span class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  text-left
                  font-size-lg
                ">{{ convertJenisPerubahan(item.mJenisPerubahanValues)}}</span>
                </td>
                <td class="text-left">
                  <span class="
                  text-dark-75
                  text-left
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ item.tanggalPerubahan | moment('DD-MM-YYYY')}}</span>
                </td>
                <td class="text-left">
                  <span
                  v-if="item.tanggalPersetujuan" class="
                  text-dark-75
                  text-left
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ item.tanggalPersetujuan | moment('DD-MM-YYYY')}}</span>
                <span
                  v-else class="
                  text-dark-75
                  text-left
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ item.tanggalPersetujuan | moment('DD-MM-YYYY')}}</span>
                </td>
                <td>
                  <section>
                  <b-button
                    variant="success"
                    size="sm"
                    @click="detailPerubahan(item)"
                    style="width:80px;"
                  >
                  >> Detail
                  </b-button>
                  </section>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="listPermohonan.length"
            :per-page="perPage"
            @change="fetchData()"
            aria-controls="my-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div v-if="pemerintaPusat">
      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Permohonan Akses Detail Ormas Badan Hukum</span>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col cols="11">
          <!-- <div class="form-row"> -->
          <div class="d-flex justify-content-between align-items-center p-1 pr-3"
            style="border-radius:8px; border:solid 1px;">
            <input type="text" class="form-control border-0" v-model="search" v-on:keyup.enter="searching()"
              :placeholder="'Cari...'" />
            <b-button size="sm" style="width:80px;margin-right:10px;" variant="primary" @click="searching()">
              <v-icon style="color:white;" small>mdi-magnify</v-icon>
              Cari
            </b-button>
          </div>

          <!-- </div> -->
        </b-col>
        <b-col cols="1" class="d-flex align-items-center p-0">
          <b-button size="sm" variant="primary" @click="showFilter()">
            <v-icon style="color:white;" small>mdi-filter</v-icon>
            Filter
          </b-button>
        </b-col>
      </b-row>
      <div class="table-responsive mt-4" style="border-radius:15px;">
        <table class="table table-head-custom table-vertical-center table-head-bg table-bordered" style="font-size:14px !important;">
          <thead>
            <tr class="text-left">
              <th style="width: 20px" class="text-capitalize">No.</th>
              <th style="min-width: 50px" class="text-capitalize">Nama Pemohon</th>
              <th style="min-width: 50px" class="text-capitalize">NIP Pemohon</th>
              <th style="min-width: 50px" class="text-capitalize">Instansi Pemohon</th>
              <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
              <th style="min-width: 50px" class="text-capitalize">Jenis Ormas</th>
              <th style="min-width: 50px" class="text-capitalize">Aksi</th>
            </tr>
          </thead>
          <tbody v-if="loadingBadanHukum">
            <template>
              <tr>
                <td colspan="6"> -- Sedang memuat -- </td>
              </tr>
            </template>
          </tbody>
          <tbody v-if="!loadingBadanHukum && listmintaobh.length === 0">
            <template>
              <tr>
                <td colspan="6"> -- Tidak ada data -- </td>
              </tr>
            </template>
          </tbody>
          <tbody style="background: #FFFFFF;">
            <template v-for="(item, i) in listmintaobh">
              <tr v-bind:key="i">
                <td >
                  <span class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ (i + 1) + ((currentPageBadanHukum-1)*perPageBadanHukum) }}</span>
                </td>
                <td class="text-left">
                  <span  class="
                  text-dark-75
                  font-weight-bolder
                  text-left
                  font-size-lg
                "> {{ item.nama_user }}
                  </span>
                </td>
                <td class="text-left">
                  <span class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  text-left
                  font-size-lg
                ">{{ item.nip_user }}</span>
                </td>
                <td class="text-left">
                  <span class="
                  text-dark-75
                  text-left
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ item.nama_instansi }}</span>
                </td>
                <td class="text-left">
                  <span class="
                  text-dark-75
                  text-left
                  font-weight-bolder
                  d-block
                  font-size-lg
                ">{{ item.nama_ormas }}</span>
                </td>
                <td class="text-left">
                  <span v-if="item.jenis_ormas == 1" class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  text-left
                  font-size-lg
                ">{{ "Perkumpulan" }}</span>
                <span v-if="item.jenis_ormas == 2" class="
                  text-dark-75
                  font-weight-bolder
                  d-block
                  text-left
                  font-size-lg
                ">{{ "Yayasan" }}</span>
                </td>
                <td>
                  <section>
                        <b-button v-if="item.status === '2' || (item.status === '3')" variant="success" size="sm" @click="getDetailPermohonan(item.id)" style="width:80px;">
                          {{ '>> Detail'}}
                        </b-button>
                        <b-button v-if="item.status === '1'" variant="success" size="sm" @click="getProsesPermohonan(item.id)" style="width: 80px;background-color: #053A69;border-color: #053A69;">
                          {{ 'Proses'}}
                        </b-button>
                  </section>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-pagination v-model="currentPageBadanHukum" :total-rows="totalRowsBadanHukum" :per-page="perPageBadanHukum" aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "Informasi",
  data() {
    return {
      det: null,
      baseURL_bakkum: process.env.VUE_APP_APPLICATION_BAKUM_URL,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      ormasIdentify : null,
      currentPage: 1,
      currentPageBadanHukum: 1,
      currentPageBadanHukums: 0,
      perPageBadanHukum: 20,
      totalRowsBadanHukum: 0,
      perPage: 20,
      pengurus: null,
      tabDetailRiwayat: 0,
      listPermohonan: [],
      listPengurus: [],
      filter: false,
      currentData: [],
      changedData: [],
      paginateData: [],
      listmintaobh: [],
      detailaksesobh: {
        id: 0,
        nama_ormas: '',
        id_user: 0,
        tanggal_berakhir_akses: '',
        status: '',
        keterangan: '',
        nomor_sk: '',
        tanggal_permintaan: '',
        tanggal_akses: '',
        alasan: '',
        path_file: '',
        nama_kabkot: '',
        nama_prov: '',
        nama_user: '',
        nip_user: '',
        nama_instansi: '',
        jenis_ormas: 0
      },
      nama: '',
      nomor_akta_terakhir: 0,
      jenis_ormas_badan_hukum: 0,
      tanggal_akta_terakhir: '',
      kedudukan_provinsi_id: '',
      nama_kedudukan_provinsi: '',
      kedudukan_kabupaten_id: '',
      nama_kedudukan_kabupaten: '',
      nama_notaris: '',
      kedudukan_notaris: '',
      notaris_pengganti: '',
      nomor_sk: '',
      tanggal_sk: '',
      ket_sosial: '',
      ket_sosial_lainnya: '',
      ket_kemanusiaan: '',
      ket_kemanusiaan_lainnya: '',
      ket_keagamaan: '',
      ket_keagamaan_lainnya: '',
      domisili_terakhir: {
        kode_kecamatan: '',
        kode_kelurahan: '',
        rt: '',
        rw: '',
        alamat: ''
      },
      // pengurus: [],
      maksud: {
        asas_tujuan: '',
      },
      loadingBadanHukum: false,
      idperubahan: null,
      statusPerubahan: '',
      tanggalPersetujuanV: '',
      loadVerif: false,
      pemerintaDearah: false,
      pemerintaPusat: false,
      typeUser: '',
      durasi: 0,
      alasan_tolak: '',
      id_akses: 0,
      id_aksess: 0,
      hari_deh: new Date(),
      
    };
  },
  mounted() {
    if(localStorage.getItem('profile')){
      var dd = JSON.parse(localStorage.getItem('profile')).profilUser
      if(dd && dd.masterKesbangpol && dd.masterKesbangpol.kabupaten_kota_kode !== null){
        this.typeUser = 'kab'
        this.pemerintaDearah = true
        this.pemerintaPusat = false
      }else if(dd && dd.masterKesbangpol && dd.masterKesbangpol.kabupaten_kota_kode == null) {
        this.typeUser = 'prov'
        this.pemerintaDearah = true
        this.pemerintaPusat = false
      } else {
        this.typeUser = 'admin'
        this.pemerintaDearah = false
        this.pemerintaPusat = true
      }
    }
    this.fetchData()
    this.getListPermohonanOBH()
  },
  watch:{
    activeTab(/*to*/){
      
    },

    currentPageBadanHukum(to){
      this.currentPageBadanHukums = to
      this.getListPermohonanOBH()
    },
  },
  methods: {
    showFilter(){
      this.filter = false
      this.$bvModal.show('filter')
    },
    submitFilterBadanHukum(){
      this.filter = true
      this.$bvModal.hide('filter')
    },
    delFilterBadanHukum(){
      this.submitFilterBadanHukum()
    },
    searchingBadanHukum(){
    },
    newTab(url){
      window.open(url);
    },
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    fetchData(){
      this.$store
        .dispatch("getListPermohonanPerubahan")
        .then((res) => {
          this.listPermohonan = res.data.data
          this.paginate()
        })
    },
    convertJenisPerubahan(val){
      var bb = ''
      for(var i = 0; i < val.length; i++){
        bb += ' ' + val[i] + ','
      }
      var aa = bb.slice(0,-1)
      return aa
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.paginateData = this.listPermohonan.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    getStructureOrganization(id){
      this.$store
        .dispatch("getStructureOrganization", id)
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          this.pengurus = res.data.pengurusInti
        })
    },
    getDetailPerubahanAfter(id){
      this.$store
        .dispatch("getlistpermohonandetail", id)
        .then((res) => {
          let curdata = res.data.data.currentData
          this.currentData = curdata
          this.changedData = res.data.data.changedData
          this.det.profil = curdata.profil
          this.$bvModal.show('modal-detail-permohonan')
        })
    },
    detailPerubahan(item){
      this.loading = true
      this.$store
      .dispatch("getDataPerubahan", item.id)
      .then((res) => {
        this.idperubahan = item.id
        this.statusPerubahan = item.statusValues
        this.tanggalPersetujuanV = item.tanggalPersetujuan
        this.loading = false
        this.det = res.data.data
        this.getStructureOrganization(item.dataOrmasId)
        this.getProfileInfo(item.dataOrmasId)
        this.getDetailPerubahanAfter(item.id)
        this.$bvModal.show('modal-detail-permohonan')
      })
    },
    getProfileInfo(id){
      this.$store
        .dispatch("getDetailDataOrganisasi", id)
        .then((res) => {
          this.ormasIdentify = res.data
        })
    },
    getListPermohonanOBH(){
      this.loadingBadanHukum = true
      this.totalRowsBadanHukum = 0
      if (this.currentPageBadanHukums === 0) {
      this.currentPageBadanHukum = 1
      } else {
      this.currentPageBadanHukum = this.currentPageBadanHukums
      }
      axios
      .get(this.baseURL_bakkum + 'aksespemerintahdaerah/list?page=' + this.currentPageBadanHukum)
      .then((listmintaobhs) => {
        var l = 0;
        for(l=0; l<listmintaobhs.data.data.length; l++){
          this.listmintaobh.push(listmintaobhs.data.data[l]);
        }
      this.loadingBadanHukum = false
      
      this.totalRowsBadanHukum = listmintaobhs.data.totalCount

      this.hari_ini = (this.hari_deh.getDate() + ' -'  + (this.hari_deh.getMonth()+1) + ' -'  + this.hari_deh.getFullYear())
      })
    },
    getDetailPermohonan(itemdetail) {
      this.$bvModal.show('modal-detail-permit-detai-badan-hukum')
      this.getDataDetailPermohonanAkses(itemdetail)
    },
    getProsesPermohonan(itemproses) {
      this.$bvModal.show('modal-proses-permit-detai-badan-hukum')
      this.getDataDetailPermohonanAkses(itemproses)
    },
    getDataDetailPermohonanAkses(item){
      axios
      .get(this.baseURL_bakkum + 'aksespemerintahdaerah/detail/' + item)
      .then((detailaksesobhs) => {
        this.detailaksesobh = detailaksesobhs.data.data
      })
    },
    checkobh(nama, sk){
      Swal.showLoading({allowOutsideClick: false})
      // this.data_pengurus_badanhukum = []
      axios
          .get(this.baseURL_bakkum + 'getDetailBadanHukum?nama=' + nama + '&nomor_sk=' + sk)
          .then((res) => {
            this.nama = res.data.data[0].nama
            this.nomor_akta_terakhir = res.data.data[0].nomor_akta_terakhir
            this.tanggal_akta_terakhir = res.data.data[0].tanggal_akta_terakhir
            this.kedudukan_provinsi_id = res.data.data[0].kedudukan_provinsi_id
            this.nama_kedudukan_provinsi = res.data.data[0].nama_kedudukan_provinsi
            this.kedudukan_kabupaten_id = res.data.data[0].kedudukan_kabupaten_id
            this.nama_kedudukan_kabupaten = res.data.data[0].nama_kedudukan_kabupaten
            this.nama_notaris = res.data.data[0].nama_notaris
            this.kedudukan_notaris = res.data.data[0].kedudukan_notaris
            this.notaris_pengganti = res.data.data[0].notaris_pengganti
            this.nomor_sk = res.data.data[0].nomor_sk
            this.tanggal_sk = res.data.data[0].tanggal_sk
            this.ket_sosial = res.data.data[0].ket_sosial
            this.ket_sosial_lainnya = res.data.data[0].ket_sosial_lainnya
            this.ket_kemanusiaan = res.data.data[0].ket_kemanusiaan
            this.ket_kemanusiaan_lainnya = res.data.data[0].ket_kemanusiaan_lainnya
            this.ket_keagamaan = res.data.data[0].ket_keagamaan
            this.ket_keagamaan_lainnya = res.data.data[0].ket_keagamaan_lainnya
            this.domisili_terakhir = {
              kode_kecamatan: res.data.data[0].domisili_terakhir.kode_kecamatan,
              kode_kelurahan: res.data.data[0].domisili_terakhir.kode_kelurahan,
              rt: res.data.data[0].domisili_terakhir.rt,
              rw: res.data.data[0].domisili_terakhir.rw,
              alamat: res.data.data[0].domisili_terakhir.alamat
            }
            this.maksud = {
              asas_tujuan: res.data.data[0].maksud.asas_tujuan
            }
            
            
            var penguruss = res.data.data[0].pengurus;
            
            this.pengurus = penguruss
            
            if(this.pengurus[0].data.jenis_pengurus == "ORGAN PERKUMPULAN"){
              this.jenis_ormas_badan_hukum = 1
            }
            if(this.pengurus[0].data.jenis_pengurus == "ORGAN YAYASAN"){
              this.jenis_ormas_badan_hukum = 2
            } else {
              this.jenis_ormas_badan_hukum = 0
            }
            Swal.close()
            this.$bvModal.show('modal-profil-badan-hukum')
        })
    },
    submitTolakMintaAkses(idakses) {
      this.$bvModal.hide('modal-proses-permit-detai-badan-hukum')
      this.$bvModal.show('modal-kasih-permit-detai-badan-hukum')
      this.id_aksess = idakses;
    },
    // submitIzinkanMintaAkses(idakse) {
    //   this.$bvModal.hide('modal-proses-permit-detai-badan-hukum')
    //   this.$bvModal.show('modal-kasih-permit-detai-badan-hukum')
      

    // },
    submitIzinkanMintaAkses(idakse) {
      this.listmintaobh = []
      this.id_akses = idakse
      this.$bvModal.hide('modal-proses-permit-detai-badan-hukum')
      const todaySKA = new Date();
      var dateSKA = '';
      if ((todaySKA.getMonth()+1) < 10 && todaySKA.getDate() < 10) {
        dateSKA = todaySKA.getFullYear() + '-0' + (todaySKA.getMonth()+1) + '-0' + todaySKA.getDate();
      } if ((todaySKA.getMonth()+1) < 10) {
        dateSKA = todaySKA.getFullYear() + '-0' + (todaySKA.getMonth()+1) + '-' + todaySKA.getDate();
      } if (todaySKA.getDate() < 10) {
        dateSKA = todaySKA.getFullYear() + '-' + (todaySKA.getMonth()+1) + '-0' + todaySKA.getDate();
      } else {
        dateSKA = todaySKA.getFullYear() + '-' + (todaySKA.getMonth()+1) + '-' + todaySKA.getDate();
      }
      var timeSKA = '';
      if (todaySKA.getHours() < 10 && todaySKA.getMinutes() < 10 && todaySKA.getSeconds() < 10) {
      timeSKA = '0' + todaySKA.getHours() + ":" + '0' + todaySKA.getMinutes() + ":" + '0' + todaySKA.getSeconds();
      } if (todaySKA.getMinutes() < 10 && todaySKA.getSeconds() < 10) {
      timeSKA =todaySKA.getHours() + ":" + '0' + todaySKA.getMinutes() + ":" + '0' + todaySKA.getSeconds();
      } if (todaySKA.getSeconds() < 10) {
      timeSKA =todaySKA.getHours() + ":" + todaySKA.getMinutes() + ":" + '0' + todaySKA.getSeconds();
      } if (todaySKA.getHours() < 10 && todaySKA.getMinutes() < 10) {
      timeSKA = '0' + todaySKA.getHours() + ":" + '0' + todaySKA.getMinutes() + ":" + todaySKA.getSeconds();
      } if (todaySKA.getHours() < 10 ) {
      timeSKA = '0' + todaySKA.getHours() + ":" + todaySKA.getMinutes() + ":" + todaySKA.getSeconds();
      } if (todaySKA.getHours() < 10 && todaySKA.getSeconds() < 10) {
      timeSKA = '0' + todaySKA.getHours() + ":" + todaySKA.getMinutes() + ":" + '0' + todaySKA.getSeconds();
      } if (todaySKA.getMinutes() < 10 ) {
      timeSKA = todaySKA.getHours() + ":" + '0' + todaySKA.getMinutes() + ":" + todaySKA.getSeconds();
      } else {
        timeSKA = todaySKA.getHours() + ":" +  todaySKA.getMinutes() + ":" + todaySKA.getSeconds();
      }
      const todayTO = new Date(todaySKA);
      // todayTO.setDate(todaySKA.getDate()+this.durasi);
      // console.log(todayTO.getDate()+1)
      // todayTO.toLocaleDateString();
      var dateTO = '';
      if ((todayTO.getMonth()+1) < 10 && todayTO.getDate() < 10) {
        dateTO = todayTO.getFullYear() + '-0' + (todayTO.getMonth()+1) + '-0' + (todaySKA.getDate()+1);
      } if ((todayTO.getMonth()+1) < 10) {
        dateTO = todayTO.getFullYear() + '-0' + (todayTO.getMonth()+1) + '-' + (todaySKA.getDate()+1);
      } if ((todaySKA.getDate()+this.durasi) < 10) {
        dateTO = todayTO.getFullYear() + '-' + (todayTO.getMonth()+1) + '-0' + (todaySKA.getDate()+1);
      } else {
        dateTO = todayTO.getFullYear() + '-' + (todayTO.getMonth()+1) + '-' + (todaySKA.getDate()+1);
      }
      const stime = "T";
      const sZone = "Z";
      const timestampSKA = dateSKA + stime + timeSKA + sZone;
      const timestampTO = dateTO + stime + timeSKA + sZone;
      axios
      .patch(this.baseURL_bakkum + 'aksespemerintahdaerah/updatedetail/' + this.id_akses, {
        tanggal_akses: timestampSKA,
        tanggal_berakhir_akses: timestampTO,
        status: "2",
      })
      // .then((response) => {
      //     this.$bvModal.show('modal-permitselesi-detai-badan-hukum')
      //     
      // })
      this.getListPermohonanOBH();
    },
    GakKasihAkses() {
      this.listmintaobh = []
      this.$bvModal.hide('modal-kasih-permit-detai-badan-hukum')
      const today = new Date();
      var date = '';
      if ((today.getMonth()+1) < 10 && today.getDate() < 10) {
        date = today.getFullYear() + '-0' + (today.getMonth()+1) + '-0' + today.getDate();
      } if ((today.getMonth()+1) < 10) {
        date = today.getFullYear() + '-0' + (today.getMonth()+1) + '-' + today.getDate();
      } if (today.getDate() < 10) {
        date = today.getFullYear() + '-' + (today.getMonth()+1) + '-0' + today.getDate();
      } else {
        date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
      }
      var time = '';
      if (today.getHours() < 10 && today.getMinutes() < 10 && today.getSeconds() < 10) {
      time = '0' + today.getHours() + ":" + '0' + today.getMinutes() + ":" + '0' + today.getSeconds();
      } if (today.getMinutes() < 10 && today.getSeconds() < 10) {
      time =today.getHours() + ":" + '0' + today.getMinutes() + ":" + '0' + today.getSeconds();
      } if (today.getSeconds() < 10) {
      time =today.getHours() + ":" + today.getMinutes() + ":" + '0' + today.getSeconds();
      } if (today.getHours() < 10 && today.getMinutes() < 10) {
      time = '0' + today.getHours() + ":" + '0' + today.getMinutes() + ":" + today.getSeconds();
      } if (today.getHours() < 10 ) {
      time = '0' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      } if (today.getHours() < 10 && today.getSeconds() < 10) {
      time = '0' + today.getHours() + ":" + today.getMinutes() + ":" + '0' + today.getSeconds();
      } if (today.getMinutes() < 10 ) {
      time = today.getHours() + ":" + '0' + today.getMinutes() + ":" + today.getSeconds();
      } else {
        time = today.getHours() + ":" +  today.getMinutes() + ":" + today.getSeconds();
      }
      const stime = "T";
      const sZone = "Z";
      const timestamp = date + stime + time + sZone;
      axios
      .patch(this.baseURL_bakkum + 'aksespemerintahdaerah/updatedetail/' + this.id_aksess, {
        id_user: this.id_user,

        tanggal_akses: timestamp,
        status: "3",
        keterangan: this.alasan_tolak,
      })
      // .then((response) => {
      //     this.$bvModal.show('modal-permitselesi-detai-badan-hukum')
      //     
      // })
      this.getListPermohonanOBH();
    },
    verifPerubahan(val){
      this.loadVerif = true
      this.$store
      .dispatch("verifikasiPerubahan", [this.idperubahan, {status: val}])
      .then(() => {
        this.fetchData()
        setTimeout(() => {
          this.$bvModal.hide('modal-detail-permohonan')
          this.loadVerif = false  
        }, 1000);
      }).catch(() => {
        this.loadVerif = false  
      })
    }
  },
  computed: {
    
  }
};
</script>